


























































































































import {  Order, OrderLine, Product, Source } from "@/models";
import EmptyView from "@/components/EmptyView.vue";
import Search from "@/components/VEntrySearch.vue";
import {
  Vue,
  Component,
  Mixins,
  Watch
} from "vue-property-decorator";
import { namespace } from "vuex-class";
import InvoiceLinesMixin from "@/mixins/http/InvoiceLinesMixin";
import { RegisterHttp } from "@/utils/Decorators";
import StoreSubscriber from "@/store/StoreSubscriber";
import DataTable from "@monade/vue-components/src/components/DataTable.vue";
import VPageContentSticky from "@/components/VPageContentSticky.vue";
import { InvoiceLine } from "@/models";
import VDatePicker from "@/components/DatePicker.vue";
import { NoCache } from "@/utils/Decorators";
import moment from "moment";
import { RequestData } from "@/store/types/RequestStore";
import SourceSelect from "@/components/Source/Select.vue";
import { easync } from "@/utils/http";
import { showToast } from "@/utils/Toast";
import { sourceStore } from "@/store/typed";
import ManufactureSelect from '@/components/Manufacture/Select.vue';
import OrderSelect from '@/components/Order/Select.vue';

const getTag = "get_print_templates";
const dumpTag = "destroy_print_template";

@Component({
  components: {
    Search,
    EmptyView,
    VDatePicker,
    SourceSelect,
    VPageContentSticky,
    DataTable,
    ManufactureSelect,
    OrderSelect
  }
})
export default class InvoiceLineList extends Mixins(InvoiceLinesMixin) {
  @RegisterHttp(getTag) getRequest: RequestData;
  @RegisterHttp(dumpTag) dumpRequest: RequestData;

  items: InvoiceLine[] = [];
  page = 1;
  total: number|null = null;
  dumping = false;

  get source() {
    return sourceStore.currentSource;
  }

  async makeDump() {
    this.dumping = true;

    const [data, errors] = await easync(this.dumpInvoiceLines(this.filter));
    if (errors) {
      showToast("Sì è verificato un errore durante il dump", { type: "error" });
    }

    this.dumping = false;
  }

  columns = [
    { name: "name", label: "Oggetto" },
    { name: "externalOrderId", label: "Numero ordine" },
    { name: "product", label: "Prodotto" },
    { name: "totalPieces", label: "Pezzi" },
    { name: "manufactures", label: "Lavorazioni" },
    { name: "total", label: "Totale" },
    { name: "createdAt", label: "" }
  ];

  filter: any = {
    source: sourceStore.currentSource?.id,
    range: {
      from: moment(new Date())
        .startOf("day")
        .format("YYYY-MM-DD"),
      to: moment(new Date())
        .endOf("day")
        .format("YYYY-MM-DD")
    }
  };

  search: string | null = null;

  created() {
    this.subscribe();
  }

  onDateSelected(data: any) {
    this.page = 1;
    this.filter = {...this.filter, ...data};
    this.loadItems();
  }

  onSourceSelected(sourceId: string) {
    this.filter.source = sourceId;
    this.loadItems();
  }

  private onManufactureSelected(manufactureId: string) {
    this.filter.manufacture = manufactureId;
    this.loadItems();
  }

  get orderSelectFilter() {
    return {
      withInvoiceLines: true
    }
  }

  private onOrderSelected(order: Order) {
    this.filter.invoiceableExternalId = order.externalId;
    this.loadItems();
  }

  onCreated(item: InvoiceLine) {
    this.items.unshift(item);
  }

  onUpdated(item: InvoiceLine) {
    const index = this.items.findIndex(o => item.id === o.id);
    Vue.set(this.items, index, item);
  }

  async searchItems(name: string) {
    if (name.trim().length > 0) {
      this.search = name;
      const items = await this.getInvoiceLines(this.payload, getTag);
      return items;
    }
  }

  onSearchCanceled() {
    this.page = 1;
    this.search = null;
    this.loadItems();
  }

  onSearched(items: {items: InvoiceLine[]}) {
    this.page = 1;
    this.items = items.items;
  }

  @Watch("page")
  onPageChanged(value: any, oldValue: any) {
    if (value == 1 && oldValue) {
      this.items = [];
    }
    this.loadItems();
  }

  mounted() {
    if (sourceStore.currentSource) {
      (this.filter.source = sourceStore.currentSource?.id), this.loadItems();
    }
  }

  subscribe() {
    StoreSubscriber.subscribe("source/setCurrentSource", this.onSourceSet);
  }

  onSourceSet(source: Source | undefined) {
    if (source) {
      this.loadItems();
    }
  }

  nextPage() {
    this.page += 1;
  }

  @NoCache
  get payload() {
    const data = {
      per: 20,
      include: "*,invoice_line_manufactures.manufacture,invoiceable.product,invoiceable.order_lines.product",
      page: this.page,
      filter: { ...this.filter }
    };

    return data;
  }

  async loadItems() {
    const [data, errors] = await easync(this.getInvoiceLines(this.payload, getTag));
    if (errors) {
      if (this.page > 1) {
        this.page -= 1;
      }
      return;
    }

    if (this.page === 1) {
      this.items = [];
      this.total = this.meta.total;
    }
    this.items.push(...data);
  }

  get loading() {
    return this.getRequest?.loading;
  }

  get meta() {
    return this.getRequest?.meta;
  }

  private getProducts(invoiceLine: InvoiceLine): Product[] {
    return invoiceLine.invoiceableType === 'Order' ?
      (invoiceLine.invoiceable as Order).orderLines.map(el => el.product) :
      [(invoiceLine.invoiceable as OrderLine).product];
  }
}
