













import { Vue, Component, Prop, Mixins } from "vue-property-decorator";
import VSelect, { VSelectOption } from "@/components/VSelect.vue";
import { RequestData } from "@/store/types/RequestStore";
import { Manufacture, Order } from "@/models";
import { easync } from "@/utils/http";
import { showErrorToast } from "@/utils/Toast";
import { RegisterHttp } from "@/utils/Decorators";
import OrdersMixin, { OrderTags } from "@/mixins/http/OrdersMixin";

@Component({ components: { VSelect } })
export default class OrderSelect extends Mixins(OrdersMixin) {
  @Prop()
  selected!: string | null;

  @Prop()
  queryKey?: string;

  @Prop()
  label?: string;

  @Prop()
  placeholder?: string;

  @Prop({ default: false })
  preselect: boolean;

  @Prop({ default: false })
  actsAsFilter: boolean;

  @Prop()
  filter: any;

  options: VSelectOption[] = [];
  private items: Order[] = [];

  private value: string | null = this.selected || null;

  @RegisterHttp(OrderTags.Index) request!: RequestData;

  mounted() {
    this.loadItems();
  }

  get payload() {
    let data: any = {
      page: {
        size: 100
      }
    };

    if (this.filter) {
      data.filter = this.filter;
    }

    return data;
  }

  get filterDefault() {
    return { id: "all", text: "All" };
  }

  get loading() {
    return !!this.request?.loading;
  }

  onSelected(id: string) {
    this.value = id;
    this.$emit("input", id);

    const foundOrder = this.items.find(el => el.id === id);
    this.$emit('inputRaw', foundOrder);

    if (this.queryKey && this.$route.query[this.queryKey] !== this.value) {
      let query = this.$route.query;
      query[this.queryKey] = this.value;

      this.$router.push({ query });
    }
  }

  onItemsReady() {
    if (!this.value) {
      if (this.preselect) {
        this.value = this.options[0].id;
      }
    } else {
      this.value = this.options.find(o => o.id === this.selected)?.id;
    }
  }

  async loadItems() {
    if (this.actsAsFilter) {
      this.options.unshift(this.filterDefault);
    }

    const [data, errors] = await easync(this.getOrders(this.payload, OrderTags.Index));
    if (errors) {
      showErrorToast("Si è verificato un errore");
      return;
    }

    this.items = data;
    this.options = data.map((o: Order) => {
      return { id: o.id, text: `${o.externalId}` };
    });
    this.onItemsReady();
  }
}
