import { authStore } from "@/store/typed";
import { BASE_API_URL } from "@/utils/Constants";
import jsFileDownloader from "js-file-downloader";
import qs from "qs";
import { Component, Vue } from "vue-property-decorator";
import { Getter, namespace } from "vuex-class";

const auth = namespace("auth");

const BASE_INVOICE_LINE_URL = BASE_API_URL + "invoice_lines"
const BASE_DOWNLOADS_URL = BASE_API_URL + "downloads"

@Component
export default class InvoiceLinesMixin extends Vue {
  getInvoiceLines(data: any, tag: string) {
    return this.$httpGetQuery(
      BASE_INVOICE_LINE_URL,
      tag,
      data
    );
  }

  destroyInvoiceLine(id: string | number, tag: string) {
    return this.$httpDelete(BASE_INVOICE_LINE_URL + "/" + id, tag);
  }

  async dumpInvoiceLines(data: any) {
    let url = BASE_DOWNLOADS_URL + "/invoice_lines.xlsx";
    const query = qs.stringify(data, { arrayFormat: "brackets" })
    if(Object.keys(query)){
      url = `${url}?${query}`
    }

    return await new jsFileDownloader({
      url: url,
      headers: [
        { name: "Authorization", value: `Bearer ${authStore.accessToken}` }
      ]
    });
  }
}
