var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"page-content"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-6 col-md-3"},[_vm._m(1),_c('v-date-picker',{attrs:{"i18n":"EN","endDate":new Date(),"date":new Date(),"dateTo":new Date()},on:{"selected":_vm.onDateSelected}})],1),_c('div',{staticClass:"col-12 col-sm-6 col-md-3"},[_vm._m(2),_c('source-select',{attrs:{"selected":_vm.source ? _vm.source.id : null},on:{"input":_vm.onSourceSelected}})],1),_c('div',{staticClass:"col-12 col-sm-6 col-md-3"},[_vm._m(3),_c('manufacture-select',{on:{"input":_vm.onManufactureSelected}})],1),_c('div',{staticClass:"col-12 col-sm-6 col-md-3"},[_vm._m(4),_c('order-select',{attrs:{"filter":_vm.orderSelectFilter},on:{"inputRaw":_vm.onOrderSelected}})],1),_c('div',{staticClass:"col-12 col-sm-4 col-md-3 my-4"},[(_vm.total)?_c('div',{staticClass:"m-auto"},[_c('p',{staticClass:"font-size-28 text-primary my-auto"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.total))+" ")]),_c('button',{staticClass:"btn btn-outline-primary px-2 py-1 font-size-10",attrs:{"disabled":_vm.dumping},on:{"click":_vm.makeDump}},[_vm._v(" Crea excel "),(_vm.dumping)?_c('fa-icon',{attrs:{"icon":"circle-notch","spin":""}}):_vm._e()],1)]):_vm._e()])]),_c('data-table',{attrs:{"columns":_vm.columns,"values":_vm.items,"loading":_vm.loading},scopedSlots:_vm._u([{key:"column_total",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"my-auto font-weight-bold"},[_vm._v(_vm._s(_vm._f("currency")(value.total)))])])]}},{key:"column_product",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"d-flex flex-column justify-content-center"},_vm._l((_vm.getProducts(value)),function(product,index){return _c('div',{key:product.id + index},[_c('router-link',{attrs:{"to":{ name: 'products_show', params: { id: product.id } }}},[_vm._v(_vm._s(product.name))])],1)}),0)]}},{key:"column_manufactures",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"my-auto"},[_vm._v(_vm._s(value.manufactures))])])]}},{key:"column_createdAt",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"my-auto"},[_vm._v(_vm._s(_vm._f("moment")(value.createdAt,'DD/MM/YYYY'))+" alle "+_vm._s(_vm._f("moment")(value.createdAt,'HH:mm')))])])]}},{key:"column_externalOrderId",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"d-flex"},[_c('router-link',{staticClass:"my-auto",attrs:{"tag":"a","to":{
              name: 'orders_show',
              params: { id: value.orderId }
            }}},[_vm._v(_vm._s(value.invoiceableExternalId))])],1)]}},{key:"loading",fn:function(){return [(_vm.loading)?_c('div',{staticClass:"page-content p-5 d-flex w-100 my-5"},[_c('fa-icon',{staticClass:"text-primary m-auto",attrs:{"icon":"circle-notch","size":"3x","spin":""}})],1):_vm._e()]},proxy:true},{key:"no-results-label",fn:function(){return [(_vm.items && _vm.items.length == 0 && !_vm.loading)?_c('empty-view',{attrs:{"primaryText":"Non c'è nessuna linea di fatturazione qui","secondaryText":"Prova a modificare l'intervallo di tempo"}}):_vm._e()]},proxy:true}])}),(_vm.meta)?_c('div',{staticClass:"d-flex"},[(_vm.page < _vm.meta.totalPages)?_c('button',{staticClass:"btn btn-outline-primary mx-auto",on:{"click":function($event){return _vm.nextPage()}}},[_vm._v(" Carica altri ")]):_vm._e()]):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"page-subtitle text-muted text-uppercase"},[_vm._v("Lista")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',[_c('small',[_vm._v("Range di date")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',[_c('small',[_vm._v("Negozio")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',[_c('small',[_vm._v("Lavorazione")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',[_c('small',[_vm._v("Ordine")])])}]

export { render, staticRenderFns }